

























import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Image } from "vant";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    [Image.name]: Image,
  },
})
export default class More extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  moreList!: Array<{ imageUrl: string; name: string; url: string }>;

  goDetails({
    name,
    url,
    needLogin = true,
  }: {
    name: string;
    url: string;
    needLogin: boolean;
  }): void {
    if (needLogin && !this.UserModule.isLogin) {
      this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
        router: this.$router,
        newPage: true,
        goBackAfterLogin: true,
      });
      return;
    }
    switch (name) {
      case "票夹":
        monitorEvent("MinePage_ClickTicketWallet", "点击查看票夹"); // 埋点：我的，点击查看票夹
        break;
      case "常用观演人":
        monitorEvent("MinePage_ClickMyContant", "点击常用观演人"); // 埋点：我的，点击常用观演人
        break;
      case "收货地址":
        monitorEvent("MinePage_ClickMyAddress", "点击收货地址"); // 埋点：我的，点击收货地址
        break;
      case "俱乐部":
        monitorEvent("MinePage_ClickJoinAct", "点击俱乐部"); // 埋点：我的，点击俱乐部
        break;
      case "集印花":
        monitorEvent("MinePage_ClickCollectCoin", "点击集印花"); // 埋点：我的，点击集印花
        break;
      case "我的评价":
        monitorEvent("MinePage_ClickComments", "点击我的评价"); // 埋点：我的，点击我的评价
        break;
      case "我的评价":
        monitorEvent("MinePage_ClickFav", "收藏订阅"); // 埋点：我的，收藏订阅
        break;
      case "云缴费":
        monitorEvent("MinePage_ClickOnlinePay", "点击云缴费"); // 埋点：我的，点击云缴费
        break;
    }
    if (name === "观察员报名") {
      window.location.href = location.origin + "/third/#/observer";
    } else if (name === "在线客服") {
      window.location.href = url;
    } else {
      this.$router.push(url);
    }
  }
}
